Vue.component('customerGiftsComponent', {
    methods:{
        goBack:function(){
            this.$router.go(-1);
        }
    },
    template: `<div class="items-view">
                  <changePasswordModalComponent></changePasswordModalComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='ready'">
                              <!--<registerComponent></registerComponent>-->
                              <loginComponent></loginComponent>
                              <div class="row">
                                  <div class="left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  </div>                                
                                  <div class="col m8 s12">
                                      <customerGiftsListComponent></customerGiftsListComponent>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>
                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`
});
